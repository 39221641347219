
export const aboutStatistics = [
  {
    label: "Safexpay Launch",
    number: "2017",
    suffix: "",
  },
  {
    label: "Employees",
    number: "250",
    suffix: " +",
  },
  {
    label: "Global Offices",
    number: "4",
    suffix: "",
  },
  {
    label: "Safexpay merchants ",
    number: "5",
    suffix: "L+",
  }
]