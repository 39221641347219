import { apiEndPoint } from "./ApiEndPoint";
import http from "./http";

class ApiService {
  getBlogs = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.blog).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getArticles = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.article).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getPressRelease = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.pressRelease).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getVacancies = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.vacancy).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getTermsAndCondition = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.termsAndCondition).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getFaq = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.faq).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getPrivacyPolicy = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.privacyPolicy).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getData = (data) => {
    return new Promise((resolve, reject) => {
      http.get(data).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getRecentBlogs = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.recentBlogs).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getAwards = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.awards).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getInterviews = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.interviews).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getBlogById = (Id) => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.blogById+Id).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getCities = (id) => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.cities+id).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getStates = () => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.states).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  getAwardById = (id) => {
    return new Promise((resolve, reject) => {
      http.get(apiEndPoint.awardById+id).then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };
};

const fetchApi = new ApiService;
export default fetchApi
