export const apiEndPoint = {
  blog: "customer/site-policy/blogs",
  vacancy: "customer/site-policy/vacancies",
  pressRelease: "customer/site-policy/press-release-and-articles/press_release",
  article: "customer/site-policy/press-release-and-articles/article",
  privacyPolicy: "customer/site-policy/privacy-policies",
  faq: "customer/site-policy/faqs",
  termsAndCondition: "customer/site-policy/terms-and-conditions",
  recentBlogs: "customer/site-policy/recent-blogs",
  awards: "customer/site-policy/awards-and-interviews/award",
  interviews: "customer/site-policy/awards-and-interviews/interview",
  blogById: "/customer/site-policy/blog-by-id/",
  cities: "customer/cities/",
  states: "customer/states",
  awardById: "customer/site-policy/awards-by-id/"
}