import React, { useEffect } from "react";
import axis from "../../assets/images/Pricing-images/Axis.png";
import jiomoney from "../../assets/images/Pricing-images/JioMoney.png";
import mastercard from "../../assets/images/Pricing-images/Mastercard.png";
import visa from "../../assets/images/Pricing-images/Visa.png";
import zest from "../../assets/images/Pricing-images/Zest.png";
import amazonpay from "../../assets/images/Pricing-images/amazonpay.png";
import amex from "../../assets/images/Pricing-images/amex.png";
import bhim from "../../assets/images/Pricing-images/bhim.png";
import gpay from "../../assets/images/Pricing-images/gpay.png";
import hdfc from "../../assets/images/Pricing-images/hdfc.png";
import kotak from "../../assets/images/Pricing-images/kotak.png";
import lazypay from "../../assets/images/Pricing-images/lazypay.png";
import mobikwik from "../../assets/images/Pricing-images/mobikwik.png";
import olamoney from "../../assets/images/Pricing-images/olamoney.png";
import paytm from "../../assets/images/Pricing-images/paytm.png";
import phonepe from "../../assets/images/Pricing-images/phonepe.png";
import rupay from "../../assets/images/Pricing-images/rupay.png";
import sbi from "../../assets/images/Pricing-images/sbi.png";
import simpl from "../../assets/images/Pricing-images/simpl.png";
import check from "../../assets/social-icons/checked-blue-green.png";
import ApplyForAccount from "../../component/ApplyForAccount";
import HOC from "../../component/HOC";

const Pricing = () => {
  useEffect(() => {
    document.title = "Pricing - Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="pricing-bg">
          <div className="about-bg-container text-center text-white font-roboto">
            <p className="text-[27px] lg:text-[40px] font-bold">
              Transparent Pricing
            </p>
            <p className="text-[13px] lg:text-[16px] font-light">
              Borderless account pricing
            </p>
          </div>

          <div className="bg-white">
            <div className="max-w-[1140px] mx-4 lg:mx-auto py-10">
              <div className="flex flex-col lg:flex-row gap-10">
                <div className="lg:w-[70%]">
                  <p className="text-[20px] text-[#283C91] font-semibold">
                    Payments Platform
                  </p>
                  <p className="text-[13px] text-[#57647C] mt-2">
                    Easily accept payments from your customers.
                  </p>
                  <p className="text-[28px] text-[#577684] mt-6 mb-2">1.85%*</p>
                  <div className="grid lg:grid-cols-2 gap-10">
                    <div>
                      <p className="text-[#283C91] font-semibold">
                        Domestic Cards
                      </p>
                      <div className="flex items-center gap-2">
                        <img src={mastercard} alt="master card" width={50} />
                        <img src={visa} alt="visa" width={50} />
                        <img src={amex} alt="amex" width={50} />
                      </div>
                    </div>
                    <div>
                      <p className="text-[#283C91] font-semibold">
                        Net Banking<sup>+</sup>
                      </p>
                      <div className="flex items-center gap-2">
                        <img src={kotak} alt="kotak" width={50} />
                        <img src={sbi} alt="sbi" width={50} />
                        <img src={axis} alt="axis" width={50} />
                        <img src={hdfc} alt="hdfc" width={50} />
                      </div>
                    </div>
                    <div>
                      <p className="text-[#283C91] font-semibold">Wallets</p>
                      <div className="flex items-center gap-2">
                        <img src={olamoney} alt="ola money" width={50} />
                        <img src={jiomoney} alt="jio money" width={50} />
                        <img src={amazonpay} alt="amazon pay" width={50} />
                        <img src={mobikwik} alt="mobikwik" width={50} />
                      </div>
                    </div>
                    <div>
                      <p className="text-[#283C91] font-semibold">
                        Buy Now Pay Later
                      </p>
                      <div className="flex items-center gap-2">
                        <img src={simpl} alt="simpl" width={50} />
                        <img src={lazypay} alt="lazypay" width={50} />
                        <img src={zest} alt="zest" width={50} />
                      </div>
                    </div>
                  </div>
                  <p className="text-[13px] text-[#57647C] mt-8 lg:w-[50%]">
                    *Pricing per transaction + GST Additional. Subject to
                    approval from banking partners
                  </p>
                </div>
                <div className="lg:w-[40%] bg-[#119BD720] p-8 rounded-[10px]">
                  <p className="text-[20px] text-[#283C91] font-semibold mt-8 mb-2">
                    OFFER
                  </p>
                  <p className="text-[28px] text-[#283C91]">RS. 0</p>
                  <div>
                    <p className="text-[#283C91] font-medium mt-8">
                      Rupay Debit Cards + UPI
                    </p>
                    <div className="flex items-center flex-wrap gap-2">
                      <img src={rupay} alt="rupay" width={50} />
                      <img src={bhim} alt="bhim" width={50} />
                      <img src={gpay} alt="gpay" width={50} />
                      <img src={phonepe} alt="phonepe" width={50} />
                      <img src={paytm} alt="paytm" width={50} />
                    </div>
                  </div>
                  <p className="text-[#283C91] mt-8 mb-2 flex gap-2 items-center">
                    <img src={check} alt="check" width={20} /> Setup Cost : Rs.0
                  </p>
                  <p className="text-[#283C91] flex gap-2 items-center">
                    <img src={check} alt="check" width={20} /> Maintenance Fee : Rs.0
                  </p>
                </div>
              </div>

              <div className="mt-20 mx-4">
                <p className="text-[20px] text-[#283C91] font-semibold">Payout</p>
                <p className="text-[13px] text-[#57647C] mt-2">Make Payouts your way.</p>
                <div className="grid lg:grid-cols-3 gap-10 my-8">
                  <div className="flex flex-col justify-end">
                    <p className="text-[#283C91] font-semibold">IMPS / UPI</p>
                    <p className="text-[28px] text-[#577684]">Rs.3</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout below 1,000</p>
                  </div>
                  <div className="flex flex-col justify-end">
                    <p className="text-[28px] text-[#577684]">Rs.4</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout between 1,000 and 25,000</p>
                  </div>
                  <div className="flex flex-col justify-end">
                    <p className="text-[28px] text-[#577684]">Rs.8</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout above 25,000</p>
                  </div>
                  <div className="flex flex-col justify-start">
                    <p className="text-[#283C91] font-semibold">RTGS</p>
                    <p className="text-[28px] text-[#577684]">Rs.8</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout above 25,000</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Minimum amount Rs. 2,00,000/-</p>
                  </div>
                  <div className="flex flex-col justify-start">
                    <p className="text-[#283C91] font-semibold">NEFT</p>
                    <p className="text-[28px] text-[#577684]">Rs.2.5</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout below 1,000</p>
                  </div>
                  <div className="flex flex-col justify-start">
                    <p className="text-[#283C91] font-semibold">Beneficiary Validation</p>
                    <p className="text-[28px] text-[#577684]">Rs.2*</p>
                    <p className="text-[13px] text-[#57647C] mt-2">Payout below 1,000</p>
                  </div>
                </div>
                <p className="text-[13px] text-[#57647C] mt-2">(*) Pricing per transaction & GST additional.</p>
              </div>
            </div>
          </div>
        </div>

        <ApplyForAccount />
      </div>
    </>
  );
};

export default HOC(Pricing);
