import React from "react";
import { useNavigate } from "react-router-dom";
import { routes_path } from "../config/routesPath";

const SheetsComponent = ({ image, signupLink }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#283C91] relative text-center grid md:flex gap-6 lg:gap-0 items-center sheets-bg">
      <div className="md:w-[50%] py-8 px-4">
        <p className="text-white text-[28px] lg:text-[38px] font-bold">
          Are Sheets your life?
        </p>
        <p className="font-light text-white mx-auto text-[14px] lg:text-[18px] py-4">
          Supports up to 10000 payments with a single spreadsheets file upload.
          That's 3 - 4 times more than any bank or other financial institutions.
        </p>
        <div className="flex flex-wrap gap-4 justify-center">
          <button
            className="text-white bg-[#2faa4a] py-2 px-6 font-medium text-[12px] lg:text-[14px] rounded-[20px] hover:scale-95 transition-all"
            onClick={() => window.open(signupLink ? signupLink : routes_path?.signup)}
          >
            Create Account
          </button>
          <button
            className="text-[#2faa4a] border-[1px] font-medium text-[12px] lg:text-[14px] border-[#2faa4a] bg-white py-2 px-6 rounded-[20px] hover:scale-95 transition-all"
            onClick={() => {
              navigate(routes_path?.contactUs);
            }}
          >
            Contact Sales
          </button>
        </div>
      </div>
      <div
        className="md:w-[50%] md:h-[450px] flex lg:absolute -top-[60px] right-0"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <img src={image} alt="sheet background" className="h-auto object-contain" />
      </div>
    </div>
  );
};

export default SheetsComponent;
