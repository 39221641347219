import industriesIcon1 from '../../assets/images/Industries-images/industry_icon_1.png';
import industriesIcon2 from '../../assets/images/Industries-images/industry_icon_2.png';
import industriesIcon3 from '../../assets/images/Industries-images/industry_icon_3.png';
import industriesIcon4 from '../../assets/images/Industries-images/industry_icon_4.png';
import industriesIcon5 from '../../assets/images/Industries-images/industry_icon_5.png';
import industyIcon5 from '../../assets/images/Products-images/aasaan.png';
import industyIcon1 from '../../assets/images/Products-images/aggregation.png';
import industyIcon6 from '../../assets/images/Products-images/apix-industry.png';
import industyIcon2 from '../../assets/images/Products-images/paylink_industry.png';
import industyIcon4 from '../../assets/images/Products-images/payouts_industry.png';
import industyIcon3 from '../../assets/images/Products-images/whitelabel_industry.png';
import { routes_path } from '../../config/routesPath';

export const d2cData = [
  {
    title: "Payment Aggregator",
    description: "Enable multiple paymodes and have the best checkout experience",
    iconUrl: industyIcon1,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Paylinks",
    description: "Receive payments by sharing payment links from shoppers",
    iconUrl: industyIcon2,
    navigationUrl: routes_path?.paymentPlatform
  },
  {
    title: "White Label",
    description: "Expand your business with your branded Payment aggregator portals designed for your needs",
    iconUrl: industyIcon3,
    navigationUrl: routes_path.whitelabel
  },
  {
    title: "Payouts",
    description: "Add multiple funds, make disbursals, send money anytime, anywhere",
    iconUrl: industyIcon4,
    navigationUrl: routes_path.payouts
  },
  {
    title: "Aasaan Checkout",
    description: "Providing shoppers with an easy checkout experience with 1 Click Checkout experience",
    iconUrl: industyIcon5,
    navigationUrl: routes_path.aasaan
  },
]

export const educationData = [
  {
    title: "Payment Aggregator",
    description: "Enable multiple paymodes",
    iconUrl: industyIcon1,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Paylinks",
    description: "Receive payments for fee collection by sharing payment links",
    iconUrl: industyIcon2,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "White Label",
    description: "Manage your fees and expenses with branded payment portals and customised dashboards",
    iconUrl: industyIcon3,
    navigationUrl: routes_path.whitelabel
  },
  {
    title: "Payouts",
    description: "Add multiple funds, make disbursals, send money anytime, anywhere for your sc",
    iconUrl: industyIcon4,
    navigationUrl: routes_path.payouts
  },
  {
    title: "APIX",
    description: "Verify your students documents and begin a seamless journey",
    iconUrl: industyIcon6,
    navigationUrl: routes_path.apix
  },
]

export const societyData = [
  {
    title: "Payment Aggregator",
    description: "Enable seamless society payments with multiple paymodes and options",
    iconUrl: industyIcon1,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Paylinks",
    description: "Receive society maintenance is easier by sharing payment links",
    iconUrl: industyIcon2,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Payouts",
    description: "Add multiple funds, make disbursals, send money  to your vendors/contractors anytime, anywhere",
    iconUrl: industyIcon4,
    navigationUrl: routes_path.payouts
  },
  {
    title: "APIX",
    description: "Verify vendor and tenant documents and enjoy a seamless journey",
    iconUrl: industyIcon6,
    navigationUrl: routes_path.apix
  },
]

export const retailData = [
  {
    title: "Payment Aggregator",
    description: "Enable multiple paymodes for your retailers",
    iconUrl: industyIcon1,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Paylinks",
    description: "Receive payments by sharing payment links",
    iconUrl: industyIcon2,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Payouts",
    description: "Add multiple funds, make disbursals, send money anytime, anywhere",
    iconUrl: industyIcon4,
    navigationUrl: routes_path.payouts
  },
  {
    title: "APIX",
    description: "Verify your retailers documents and begin your business seamlessly",
    iconUrl: industyIcon6,
    navigationUrl: routes_path.apix
  },
]

export const industriesData = [
  {
    title: "APIX",
    description: "Verify your documents and begin your business",
    iconUrl: industriesIcon3,
    navigationUrl: routes_path.apix
  },
  {
    title: "ONOX",
    description: "Empowering Business & Individuals with Financial Solutions for Everyone",
    iconUrl: industriesIcon2,
    navigationUrl: routes_path.onox
  },
  {
    title: "White Label",
    description: "Expand your business with branded PG",
    iconUrl: industriesIcon1,
    navigationUrl: routes_path.whitelabel
  },
  {
    title: "Paylinks",
    description: "Receive payments by sharing payment links",
    iconUrl: industriesIcon5,
    navigationUrl: routes_path.paymentPlatform
  },
  {
    title: "Payouts",
    description: "Add multiple funds, make disbursals, send money anytime, anywhere",
    iconUrl: industriesIcon4,
    navigationUrl: routes_path.payouts
  },
]

export const aasaanIndustryData = [
  {
    title: "Enter Mobile Number",
    description: "The shopper fills in minimal details for the first time & becomes Aasaan registered user.",
    step: "Step 1"
  },
  {
    title: "Validate Shopper & Order Details",
    description: "The next time the shopper shops, their details get auto-populated just via OTP login.",
    step: "Step 2"
  },
  {
    title: "Payment Confirmation",
    description: "The shopper pays and receives an order confirmation.",
    step: "Step 3"
  }
]
