import reviewImg1 from "../../assets/images/career-images/Ashok.png"
import reviewImg6 from "../../assets/images/career-images/Elaine.jpg"
import reviewImg3 from "../../assets/images/career-images/Harshada.jpg"
import reviewImg2 from "../../assets/images/career-images/Jaya.jpeg"
import reviewImg4 from "../../assets/images/career-images/Rohit D.jpg"
import reviewImg5 from "../../assets/images/career-images/Rohit S.jfif"
import careerBenefitImg1 from '../../assets/images/career-images/career-benefit-icon-1.png'
import careerBenefitImg2 from '../../assets/images/career-images/career-benefit-icon-2.png'
import careerBenefitImg3 from '../../assets/images/career-images/career-benefit-icon-3.png'
import careerBenefitImg4 from '../../assets/images/career-images/career-benefit-icon-4.png'
import careerBenefitImg5 from '../../assets/images/career-images/career-benefit-icon-5.png'
import careerBenefitImg6 from '../../assets/images/career-images/career-benefit-icon-6.png'
import careerBenefitImg7 from '../../assets/images/career-images/career-benefit-icon-7.png'
import careerBenefitImg8 from '../../assets/images/career-images/career-benefit-icon-8.png'

export const VacancyData = [
  {
    jobTitle: "Project Management Team Manager",
    // jobPosition: "Partnership",
    link: "https://www.linkedin.com/jobs/view/3816337650"
  },
  {
    jobTitle: "Taxation Manager",
    // jobPosition: "Engineering",
    link: "https://www.linkedin.com/jobs/view/3816339427"
  },
];

export const careerBenefitData = [
  {
    label: "Stock Options",
    iconUrl: careerBenefitImg1,
  },
  {
    label: "Flexible Timings",
    iconUrl: careerBenefitImg2,
  },
  {
    label: "Rewards & Recognition",
    iconUrl: careerBenefitImg3,
  },
  {
    label: "5-day Work Week",
    iconUrl: careerBenefitImg4,
  },
  {
    label: "Performance Bonus",
    iconUrl: careerBenefitImg5,
  },
  {
    label: "Personal Growth Development",
    iconUrl: careerBenefitImg6,
  },
  {
    label: "Wellness Program",
    iconUrl: careerBenefitImg7,
  },
  {
    label: "Part of Life at Safexpay Program",
    iconUrl: careerBenefitImg8,
  },
];

export const staffReviewData = [
  {
    review: "As a UX designer at Safexpay, I thrive on crafting visually compelling and purposeful experiences. In this dynamic environment, every pixel tells a story to me, and I'm happy to contribute to the narrative of design innovation. Safexpay's commitment to excellence fuels my passion for creating not just interfaces but memorable journeys for users.",
    name: "Ashok",
    jobRole: "UI UX",
    imageUrl: reviewImg1,
  },
  {
    review: "Working in the Accounts department has been enriching, allowing me to contribute to global financial processes and efficiently manage diverse responsibilities within the team. This dynamic and collaborative environment values efficiency and teamwork in handling financial responsibilities, making each day engaging",
    name: "Jaya",
    jobRole: "Accounts",
    imageUrl: reviewImg2
  },
  {
    review: "In my role at Safexpay's Operations, I'm dedicated to excellence with a keen focus on detail and innovative problem-solving. Collaboration is fundamental to my approach, fostering a positive team dynamic. I value Safexpay's culture of teamwork and innovation, making my work experience truly rewarding. Grateful to contribute to this team's success and looking forward to more achievements.",
    name: "Harshada",
    jobRole: "Operations",
    imageUrl: reviewImg3
  },
  {
    review: "From my POV as per Tech Front End at Safexpay, I'm happy to contribute to an organization that champions fintech innovation. The emphasis on cutting-edge technology and creativity in our projects resonates with my passion for pushing boundaries. Safexpay's commitment to fostering a dynamic tech environment allows me to thrive and make meaningful contributions to our team's success. Grateful to be part of a company that values innovation and encourages tech excellence.",
    name: "Rohit Doltade",
    jobRole: "Tech- Front End",
    imageUrl: reviewImg4
  },
  {
    review: "In my role within my product International team, I'm proud to be part of an organization that truly values global innovation. Contributing to product strategies for international markets has been a rewarding journey. Safexpay's commitment to understanding diverse market needs and fostering a culture of innovation resonates with my professional values. I appreciate the opportunity to contribute to the success of our global team.",
    name: "Rohit Sirohia",
    jobRole: "Product - International",
    imageUrl: reviewImg5
  },
  {
    review: "Within Safexpay's HR department, my role revolves around enhancing team success. Facilitating a positive workplace culture, I prioritize collaboration and support to ensure our collective achievements. Safexpay's emphasis on a positive work environment aligns with my values, and I'm proud to contribute to a team that values innovation and teamwork.",
    name: "Elaine",
    jobRole: "HR",
    imageUrl: reviewImg6
  },
]