import React, { useEffect, useRef, useState } from "react";
import leftArr from "../../assets/social-icons/pagination-arr.png";
import clsx from "clsx";
import fetchApi from "../../apiservice/ApiService";
import { apiEndPoint } from "../../apiservice/ApiEndPoint";

const ImageModal = ({ id, setShowModal, showModal }) => {
  const modalRef = useRef();
  const [newImgData, setNewImgData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetAward = () => {
    setLoading(true);
    fetchApi
      .getData(apiEndPoint.awardById + id)
      .then((response) => {
        console.log(response);
        setNewImgData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAward();
    function closePages(e) {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false);
        document.body.style.overflow = "auto";
      }
    }
    // document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", closePages);

    return () => {
      document.removeEventListener("mousedown", closePages);
      setShowModal(false);
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleChangeAward = (data) => {
    setLoading(true);
    fetchApi
      .getData(data?.url)
      .then((response) => {
        console.log(response);
        setNewImgData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    showModal && (
      <React.Fragment>
        <div className="bg-[#00000030] fixed top-0 w-full h-full z-20">
          <div className="flex justify-center items-center h-full">
            <div
              className="w-[90%] md:w-auto md:max-w-[600px] flex md:m-auto h-auto max-h-[90%] overflow-auto md:h-auto"
              ref={modalRef}
            >
              <div className="flex justify-between bg-white items-center p-6 h-[100%] rounded-[10px]">
                {newImgData?.next_award ? (
                  <img
                    src={leftArr}
                    alt="Carousel left arrow"
                    className={clsx("cursor-pointer object-scale-down w-[10%]")}
                    onClick={() => handleChangeAward(newImgData?.next_award)}
                  />
                ) : (
                  <div className="w-[10%]"></div>
                )}
                {loading ? (
                  <div className="skeleton--img w-[400px!important] h-[300px!important]">
                    <span></span>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center gap-3 max-h-[80%] w-auto max-w-[80%]">
                    <div className="flex h-[70%]">
                      <img
                        src={newImgData?.award?.image_path}
                        alt={newImgData?.award?.title}
                        className="h-auto object-contain"
                      />
                    </div>
                    <p className="text-[#32325D] h-[20%] text-[20px] font-extrabold cursor-pointer text-center">
                      {newImgData?.award?.title}
                    </p>
                  </div>
                )}
                {newImgData?.previous_award ? (
                  <img
                    src={leftArr}
                    alt="carousel right arrow"
                    className={clsx(
                      "rotate-180 cursor-pointer object-scale-down w-[10%]"
                    )}
                    onClick={() =>
                      handleChangeAward(newImgData?.previous_award)
                    }
                  />
                ) : (
                  <div className="w-[10%]"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default ImageModal;
