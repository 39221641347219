export const routes_path = {
  home: "/",
  signup: "https://onboarding.safexpay.com/register/payments-platform",
  login: "https://onboarding.safexpay.com/login",
  paymentAggregatorSignup: "https://portal.safexpay.com/signup",
  payoutSignup: "https://remittance.safexpay.com/registration",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  pricing: "/pricing",
  blog: "/blog",
  blogById: "/blog/:blogname/:id",
  viewAllBlogs: "/view-all-blog/:id",
  paymentPlatform: "/payment-aggregator",
  payouts: "/payout",
  aasaan: "/aasaan",
  whitelabel: "/whitelabel",
  neuX: "/neux",
  apix: "/apix",
  onox: "/onox",
  partnerProgram: "/partner-program",
  industries: "/industries",
  government: "/government",
  eCommerce: "/e-commerce",
  education: "/education",
  hospital: "/hospital",
  travel: "/travel-and-tourism",
  society: "/society",
  retail: "/retail",
  termsAndCondition: "/terms-and-conditions",
  FAQ: "/faq",
  privacyPoliciy: "/privacy-policy",
  career: '/career',
  mediaAndAwards: '/media-and-awards',
  developerDoc: 'https://docs.safexpay.com/',
  pgDmoLogin: "https://portal.safexpay.com/login",
  payoutDmoLogin: "https://remittance.safexpay.com/",
  error: "/*",
};
