import clsx from "clsx";
import React from "react";
import "./radiobutton.css";

const RadioButton = ({ id, name, label, value, onChange, onClick, themeColor }) => {
  return (
    <label className={clsx("flex gap-2 items-center cursor-pointer w-max text-[14px]", themeColor ? `text-[${themeColor}]` : "text-[#212121]")}>
      <input
        id={id}
        type="checkbox"
        className={clsx("checked common-component", themeColor === "#32325D" ? "radio-check-one" : "radio-check")}
        name={name}
        onChange={onChange}
        onClick={onClick}
        value={value}
      />
      {label}
    </label>
  );
};

export default RadioButton;
