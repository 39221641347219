import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import "../../assets/css/home.css";
import frontImage from "../../assets/images/Home-banner.png";
import partner from "../../assets/images/Safex Banners 2.png";
import step1 from "../../assets/images/Step1.svg";
import step2 from "../../assets/images/Step2.svg";
import step3 from "../../assets/images/Step3.svg";
import bg from "../../assets/images/page-title-bg1.jpg";
import xIcon from "../../assets/social-icons/X.png";
import checked from "../../assets/social-icons/checked-blue.png";
import PartnerModal from "../../common-component/modal/PartnerModal";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import HomeAbout from "./HomeAbout";
import { bringProficiencyData, clienteleData, productData } from "./homeData";

const Home = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.title =
      "Safexpay - Best Payment Solution for Online Payments In India";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="home-bnr-bg items-center gap-[20px] md:h-[450px] lg:h-[650px] w-full bg-[#c6f0f5]">
        <div
          className="lg:px-20 pt-8 px-6 text-center md:text-left md:w-[60%] font-NunitoSans relative z-[5]"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <p className="font-NunitoSans text-[1.2em] md:text-[40px] lg:text-[60px] xl:text-[80px] font-extrabold text-[#32325D] leading-[35px] lg:leading-[80px] flex flex-wrap items-start justify-center md:justify-start overflow-hidden md:w-max">
            <span>Make The</span>
            <span className="flex flex-col px-2 lg:px-4 h-[30px] md:h-[40px] lg:h-[80px] text-[#2575FC] dynamic-text gap-4">
              <span className="w-max">Swifter</span>
              <span className="w-max">Simpler</span>
              <span className="w-max">Safer</span>
            </span>{" "}
            <span className="block md:hidden">Move Now</span>
          </p>
          <p className="font-NunitoSans text-[1.2em] md:text-[40px] lg:text-[60px] xl:text-[80px] font-extrabold text-[#32325D] leading-[35px] lg:leading-[80px] hidden md:block">
            Move Now
          </p>
          <p className="font-NunitoSans py-3 text-[#000000] overflow-hidden text-[16px] lg:text-[24px] relative">
            Elevate your experience with our wide array of consumer-centric
            products and services, all at your fingertips.
          </p>
          <div className="grid lg:flex gap-4 mt-4 lg:mt-6">
            <button
              className="bg-[#2faa4a] flex justify-center items-baseline hover:scale-95 transition-all py-[10px] px-[26px] rounded-[80px] text-white"
              onClick={() => {
                navigate(routes_path?.contactUs);
              }}
            >
              <img src={xIcon} alt="x" className="translate-y-1" />
              perience Solutions
            </button>
          </div>
        </div>
        <div
          className="md:w-[45%] lg:w-[40%] pt-12 h-full"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <figure className="mx-auto flex w-full lg:w-max justify-center h-full">
            <img
              src={frontImage}
              className="Img w-full lg:max-w-[520px] object-cover h-auto overflow-visible"
              alt="foreground banner"
            />
          </figure>
        </div>
      </div>

      <div className="achivment-bg flex flex-col justify-center">
        <p className="text-white text-[24px] lg:text-[38px] text-center font-bold">
          Entrusting your business dreams, every day.
        </p>
        <div className="flex flex-wrap lg:grid lg:grid-cols-5 justify-center mx-auto gap-10 mt-6">
          <p className="grid text-white text-center">
            <span className="text-[38px] font-bold">
              <CountUp
                start={0}
                end={250}
                delay={0}
                enableScrollSpy={true}
                suffix="+"
              />
            </span>
            <span className="text-[18px]">Employees</span>
          </p>
          <p className="grid text-white text-center">
            <span className="text-[38px] font-bold">
              <CountUp start={0} end={4} delay={0} enableScrollSpy={true} />
            </span>
            <span className="text-[18px]">Global Offices</span>
          </p>
          <p className="grid text-white text-center">
            <span className="text-[38px] font-bold">
              <CountUp
                start={0}
                end={500}
                delay={0}
                enableScrollSpy={true}
                suffix="K+"
              />
            </span>
            <span className="text-[18px]">Merchants</span>
          </p>
          <p className="grid text-white text-center">
            <span className="text-[38px] font-bold">
              <CountUp
                start={0}
                end={100}
                delay={0}
                enableScrollSpy={true}
                suffix="+"
              />
            </span>
            <span className="text-[18px]">Bank Integrations</span>
          </p>
          <p className="grid text-white text-center">
            <span className="text-[38px] font-bold">
              <CountUp
                start={0}
                end={24}
                delay={0}
                enableScrollSpy={true}
                suffix="x"
              />
              <CountUp start={0} end={7} delay={0} enableScrollSpy={true} />
            </span>
            <span className="text-[18px]">Customer Support</span>
          </p>
        </div>
      </div>

      {/* Our products */}
      <section className="product-bg font-NunitoSans py-20">
        <div
          className="relative grid max-w-[1140px] mx-auto items-center text-center"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <p className="text-[#32325D] font-bold text-[32px] lg:text-[40px] leading-[38px] lg:leading-[45px]">
            Solutions to Empower Business
          </p>
          <p className="text-[#000] font-NunitoSans text-[18px] py-2 px-4">
            Get all the tools you need for seamless online payments and
            exceptional customer service for your business.
          </p>
        </div>

        <div className="mt-10 mx-10 flex justify-center flex-wrap gap-10 max-w-[900px] lg:mx-auto">
          {productData?.map((item, index) => {
            return (
              <div
                className="px-8 py-6 w-[272px] h-[300px] text-center cursor-pointer lg:text-left flex flex-col justify-center rounded-[20px] font-NunitoSans shadow-[0px_1px_30px_2px_#00000030] bg-white transition group"
                key={index}
                data-aos="fade-up"
                data-aos-duration={1500}
                onClick={() => {
                  navigate(item?.navigateUrl);
                }}
              >
                <div className="group-hover:hidden block transition-all duration-500">
                  <img
                    src={item?.iconUrl}
                    alt="Payment Management"
                    width={80}
                    className="mx-auto"
                  />
                  <p className="text-[#212529] text-[20px] lg:text-[20px] mt-3 text-center font-bold pb-3 px-2">
                    {item?.productName}
                  </p>
                </div>
                <p className="text-[#212529] hidden group-hover:block transition-all duration-500 text-center text-[14px] lg:text-[17px] px-2 line-clamp-3">
                  {item?.productDescription}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mt-24 flex justify-center">
          <button
            className="text-[18px] py-3 px-8 font-medium text-[#fff] bg-[#01A30F] rounded-[80px]"
            onClick={() => {
              navigate(routes_path.contactUs);
            }}
          >
            Book A Demo
          </button>
        </div>
      </section>

      {/* Our Clientele */}
      <section className="py-10">
        <p className="text-[28px] lg:text-[40px] text-[#32325D] font-bold text-center">
          Our Clientele
        </p>
        <div className="flex flex-wrap gap-10 justify-center items-center">
          {clienteleData?.map((item) => {
            return (
              <img
                src={item?.imgUrl}
                alt={item?.alt_text}
                key={item?.index}
                width={150}
              />
            );
          })}
        </div>
      </section>

      {/* Be our partner in three easy steps section */}
      <section className="py-10 product-bg">
        <div className="text-center mb-6 px-4">
          <p className="font-NunitoSans text-[30px] lg:text-[40px] font-extrabold text-[#283c91] leading-[normal] pt-3">
            Be our partner in three easy steps
          </p>
          <p className="text-[#57647C] text-[14px] lg:text-[18px]">
            Be a part of Safexpay partner reward program & get unlimited
            benefits.
          </p>
        </div>
        <div className="w-full grid lg:flex items-center max-w-[1480px] mx-auto">
          <div
            className="lg:w-[55%] px-4 lg:px-0 relative"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <figure className="h-auto flex justify-center">
              <img
                src={partner}
                className="Img h-full w-full object-cover"
                alt="partner"
              />
            </figure>
          </div>
          <div className="px-4 lg:w-[50%]">
            <div className="grid gap-10 py-5">
              <div className="flex items-start gap-4 w-full">
                <p className="border-2 border-[#2575FC] relative rounded-full p-4 bg-white steps">
                  <img src={step1} alt="step1" width={40} />
                </p>
                <p className="grid gap-1 w-[90%]">
                  <span className="text-[#283c91] font-NunitoSans text-[20px] lg:text-[22px] font-bold">
                    Step 1
                  </span>
                  <span className="text-[#283c91] font-NunitoSans text-[18px] lg:text-[20px]">
                    Easy onboarding
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[16px] mt-2">
                    Partner sign up is now easier than ever with our seamless
                    onboarding.
                  </span>
                </p>
              </div>
              <div className="flex items-start gap-4 w-full">
                <p className="border-2 border-[#2575FC] relative rounded-full p-4 bg-white steps">
                  <img src={step2} alt="step2" width={40} />
                </p>
                <p className="grid gap-1 w-[90%]">
                  <span className="text-[#283c91] font-NunitoSans text-[20px] lg:text-[22px] font-bold">
                    Step 2
                  </span>
                  <span className="text-[#283c91] font-NunitoSans text-[18px] lg:text-[20px]">
                    Get access to our intuitive dashboard
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[16px] mt-2">
                    Track transactions details effectively through our
                    comprehensive dashboard insights.
                  </span>
                </p>
              </div>
              <div className="flex items-start gap-4 w-full">
                <p className="border-2 border-[#2575FC] relative rounded-full p-4 bg-white">
                  <img src={step3} alt="step3" width={40} />
                </p>
                <p className="grid gap-1 w-[90%]">
                  <span className="text-[#283c91] font-NunitoSans text-[20px] lg:text-[22px] font-bold">
                    Step 3
                  </span>
                  <span className="text-[#283c91] font-NunitoSans text-[18px] lg:text-[20px]">
                    Start Your digital Journey
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[16px] mt-2">
                    Onboard new merchants and upsell safexpay products to enable
                    the best world-class solutions
                  </span>
                </p>
              </div>
              <div>
                <button
                  className="py-2 px-6 flex items-baseline bg-gradient-to-b from-[#119BD7] to-[#283C91] text-white rounded-[8px]"
                  onClick={() => setShowModal(true)}
                >
                  <img src={xIcon} alt="x" className="translate-y-1" />
                  perience Partner Program
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative">
        <div className="py-8 px-4 lg:py-10 lg:px-10 mt-4">
          <p
            className="text-[24px] font-NunitoSans lg:text-[46px] text-center font-bold text-[#283C91] px-4 mb-4"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            Bring proficiency to your business
          </p>
          <div className="flex items-center gap-4 justify-center px-4 mb-4"></div>
        </div>
        <div className="px-4 pb-10 lg:px-10 grid lg:flex justify-between items-start">
          <div className="lg:w-[50%] flex flex-col gap-4 relative">
            <div>
              <img
                src={bg}
                alt="bring proficiency background"
                className="rounded-[10px]"
              />
            </div>
          </div>
          <div className="lg:w-[50%] mt-6 lg:mt-0">
            <div className="text-[#57647C] text-[16px] grid lg:grid-cols-2 gap-4 font-NunitoSans lg:text-[16px] px-4 lg:px-10">
              {bringProficiencyData?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="flex gap-4 items-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <img src={checked} width={30} alt="check mark" />
                    <p className="grid">
                      <span className="font-bold">{item?.title}</span>
                      <span className="font-light">{item?.description}</span>
                    </p>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {showModal && (
        <PartnerModal showModal={showModal} setShowModal={setShowModal} pageName={"home_page"} />
      )}

      <HomeAbout />
    </>
  );
};

export default HOC(Home);
