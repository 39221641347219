import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import 'react-simple-toasts/dist/theme/failure.css';
import 'react-simple-toasts/dist/theme/success.css';
import "./App.css";
import routesConfig from "./config/routesConfig";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="relative">
        <Routes>
          {routesConfig.map((route, index) => {
            return (
              <Route path={route?.path} element={route?.element} key={index} />
            );
          })}
        </Routes>
      </div>
    </>
  );
}

export default App;
