import clsx from "clsx";
import React from "react";

const Button = ({ text, backgroundColor, onClick }) => {
  return (
    <div className="w-max">
      <button
        className={clsx(
          "text-white py-2 px-4 rounded-md"
        )}
        style={{ backgroundColor: backgroundColor || "#7A7A7A" }}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
