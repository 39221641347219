import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import xIcon from "../../assets/images/Products-images/X-white.png";
import oneApp from "../../assets/images/Products-images/one_app-multi_payment.png";
import onoxLogo from "../../assets/images/Products-images/onox_logo.svg";
import signUp from "../../assets/images/Products-images/onox_signin.png";
import logo from "../../assets/images/logo-white.svg";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import { routes_path } from "../../config/routesPath";
import {
  onoxDigitalGrowthData,
  onoxSuccessData,
  onoxWhiteLabelPortalData,
} from "./productDetailsData";

const Onox = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ONOX - Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header
        bgColor={"#0C99D5"}
        safexpayLogo={logo}
        productLogo={onoxLogo}
        xIcon={xIcon}
      />
      <div className="overflow-x-hidden">
        <div className="onox-bg grid lg:flex lg:h-[500px] items-end md:items-center bg-[#0C99D5] text-white px-4 py-10 lg:px-20 font-roboto">
          <div
            className="lg:w-[60%]"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] text-[#32325D] lg:text-[50px] font-bold">
              Open Gateway to New Opportunities with X Factor
            </p>
            <p className="font-light text-[14px] text-[#32325D] lg:text-[18px] py-4">
              Connect partners with suite of our financial services.
            </p>
            <div className="flex justify-start gap-4 mt-10">
              <button className="bg-[#000000] py-2 px-6 w-max border-[1px] border-[#0C99D5] rounded-[20px] text-[#FFFFFF] font-medium" onClick={()=>{
                navigate(routes_path?.contactUs)
              }}>
                Contact Us
              </button>
            </div>
            {/* <div className="flex flex-col md:flex-row gap-4 mt-10">
              <input
                type="number"
                placeholder="Enter Phone No."
                className="border-[1px] border-[#0C99D5] bg-[#F3F9FE] rounded-[3px] outline-none text-center p-3 text-sm text-[#000000] w-[350px]"
              />
              <button className="bg-[#000000] py-3 px-4 w-max mx-auto md:mx-0 border-[1px] border-[#0C99D5] rounded-[3px] text-[#FFFFFF] font-medium">
                Send me the link
              </button>
            </div> */}
          </div>
        </div>

        {/* Partner Section */}
        <div
          className={clsx("relative mb-10 onoxBg")}
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <p
            className={clsx(
              "absolute top-6 text-[#32325D] lg:top-10 left-[50%] translate-x-[-50%] text-[20px] lg:text-[40px] font-bold w-full text-center lg:w-max"
            )}
          >
            Looking to partner with ONOX?
          </p>
          <div className="flex items-center gap-4 w-full lg:max-w-[40%] absolute bottom-[20px] justify-center lg:bottom-[20px] left-[50%] translate-x-[-50%]">
            <button
              className={clsx(
                "bg-[#0C99D5] text-white py-2 lg:py-1 px-8 text-[12px] lg:text-[18px] font-bold rounded-[20px] hover:scale-95 transition-all"
              )}
              onClick={()=>{
                navigate(routes_path?.partnerProgram);
              }}
            >
              Tell Me How
            </button>
          </div>
        </div>

        <div className="py-10 px-6 bg-[#F3F9FE]">
          <p className="text-[28px] lg:text-[40px] font-bold text-center text-[#32325D]">
            Your Success. Our Objective
          </p>
          <p className="lg:text-[20px] font-bold text-center text-[#32325D] lg:max-w-[60%] mx-auto">
            unlock unlimited earning and commission opportunities to grow your
            business with zero capital
          </p>

          <div className="grid grid-cols-2 lg:grid-cols-4 items-center px-4 lg:px-10 py-10 gap-10 h-full">
            {onoxSuccessData?.map((item, index) => {
              return (
                <div
                  className="flex flex-col justify-between"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration={1000 * index}
                >
                  <div className="h-[110px] flex items-center">
                    <img
                      src={item?.imageUrl}
                      alt={item?.title}
                      className="w-[100px] lg:w-auto h-auto mx-auto object-contain"
                    />
                  </div>
                  <p className="lg:text-[20px] font-bold text-[#32325D] text-center mt-4">
                    {item?.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Digital growth section */}
        <div className="py-10 lg:py-20 bg-[#0C99D5] text-white">
          <p className="text-[28px] lg:text-[40px] font-bold text-center">
            Digital Growth at Your Fingertips
          </p>
          <p className="lg:text-[20px] font-bold text-center lg:max-w-[60%] mx-auto">
            ONOX app helps you to handle customers easily with fast transactions
            and operational support.
          </p>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 items-center px-4 lg:px-8 py-10 gap-8 h-full">
            {onoxDigitalGrowthData?.map((item, index) => {
              return (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  key={index}
                  className="bg-white rounded-[20px] py-10 px-6 h-[250px] flex flex-col justify-between"
                >
                  <div className="flex h-[100px] items-center">
                    <img
                      src={item?.imageUrl}
                      alt={item?.imageUrl}
                      className="h-auto mx-auto w-[100px]"
                    />
                  </div>
                  <p className="text-[20px] font-bold text-[#32325D] text-center mt-4">
                    {item?.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {/* One app section */}
        <div className="one-app-bg pb-10 pt-20">
          <p className="text-[28px] lg:text-[40px] font-bold text-center text-[#32325D]">
            1 App, Multiple Payments
          </p>
          <img
            src={oneApp}
            className="px-4 py-10 lg:p-20 w-full lg:h-screen object-contain"
            data-aos="zoom-in"
            data-aos-duration="1000"
          />
        </div>

        {/* Whitelabelled portal section */}
        <div className="pb-20">
          <p className="text-[28px] lg:text-[40px] font-bold text-center text-[#32325D]">
            White-labelled Portal For Offline Channel
          </p>

          <div className="lg:mt-20">
            {onoxWhiteLabelPortalData?.map((item, index) => {
              return (
                <div
                  key={index}
                  data-aos={
                    item.imageDirection === "left" ? "fade-right" : "fade-left"
                  }
                  data-aos-duration="1000"
                  className={clsx(
                    "flex h-full border-b-[1px] border-b-[#937979] mx-4 lg:mx-32 py-16",
                    item.imageDirection === "left"
                      ? "flex-col lg:flex-row"
                      : "flex-col lg:flex-row-reverse"
                  )}
                >
                  <div className="lg:w-[50%]">
                    <img
                      src={item?.imageUrl}
                      alt={item?.title}
                      className="min-w-[150px] w-auto max-w-[200px] h-auto mx-auto pb-8 lg:pb-0"
                    />
                  </div>
                  <div className="lg:w-[50%]">
                    <div className="lg:max-w-[70%] flex flex-col gap-6">
                      <p className="text-[20px] font-bold text-[#32325D]">
                        {item?.title}
                      </p>
                      <p className="text-[17px] font-normal text-[#000000]">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Enterprices growth section */}
        <div className="py-10 px-4 lg:px-20">
          <p className="text-[28px] lg:text-[40px] text-center lg:text-left font-bold text-[#32325D] lg:max-w-[70%]">
            Make business transactions and enterprise growth easy with Onox.
          </p>
          <div className="flex gap-4 mt-10">
              <button className="bg-[#0C99D5] py-2 px-6 w-max mx-auto md:mx-0 border-[1px] border-[#0C99D5] rounded-[20px] text-[#FFFFFF] font-medium" onClick={()=>{
                navigate(routes_path?.contactUs)
              }}>
                Contact Us
              </button>
            </div>
          {/* <div className="mt-10 lg:mt-20 grid lg:flex gap-4 lg:gap-6 items-center">
            <input
              type="number"
              placeholder="Enter Phone No."
              className="border-[1px] border-[#0C99D5] bg-[#F3F9FE] rounded p-2 text-center text-sm lg:w-[250px]"
            />
            <button className="bg-[#0C99D5] text-white py-2 px-4 rounded">
              Send me the link
            </button>
          </div> */}
        </div>

        {/* Sign up section */}
        <div className="py-10 lg:py-20">
          <p className="text-[28px] lg:text-[40px] font-bold text-[#32325D] text-center">
            Sign up in minutes. No problem.
          </p>

          <img
            src={signUp}
            alt="signup"
            className="mx-auto py-10 w-[300px] lg:w-[550px]"
            data-aos="zoom-in"
            data-aos-duration="1000"
          />

          <div className="flex items-center gap-4 justify-center">
            <button
              className={clsx(
                "text-white py-2 lg:py-1 px-8 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all bg-[#2faa4a]"
              )}
              onClick={() => {
                window.open(routes_path.developerDoc, "_blank");
              }}
            >
              View Developer Doc
            </button>
            <button
              className={clsx(
                "border-[1px] text-[12px] bg-white lg:text-[18px] py-2  lg:py-1 px-8 rounded-[20px] hover:scale-95 transition-all text-[#2faa4a] border-[#2faa4a]"
              )}
              onClick={() => {
                window.open(routes_path?.signup);
              }}
            >
              Signup Now
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Onox;
