import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import mediaKitImg from "../../assets/images/media-awards/media-kit-img.png";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import ArticleSection from "./ArticleSection";
import AwardsSection from "./AwardsSection";
import InterviewsSection from "./InterviewsSection";
import PressReleaseSection from "./PressReleaseSection";

const tab = [
  { id: "articles", label: "Articles", isComingSoon: false },
  { id: "press-releases", label: "Press Releases", isComingSoon: false },
  { id: "awards", label: "Awards", isComingSoon: false },
  { id: "interviews", label: "Interviews", isComingSoon: false },
  { id: "tv", label: "TV", isComingSoon: true },
  { id: "podcasts", label: "Podcasts", isComingSoon: true },
];

const MediaAndAwards = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Media & Awards | Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleScrollTo = (index, id) => {
    setTabValue(index);
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <div>
        <div className="media-award-bg flex justify-end items-end md:justify-start md:items-center">
          <div className="text-white w-full bg-[#2575FC] md:bg-transparent md:w-[40%] px-4 py-8 md:p-10 grid gap-2 md:mx-10">
            <p className="text-[24px] md:text-[38px] font-extrabold">
              Medialab
            </p>
            <p className="md:text-[18px]">
              Dive into the Buzz: News, Updates, Press Releases and More
            </p>
          </div>
        </div>

        <div className="flex gap-10 items-center md:justify-evenly py-10 md:mx-20 mx-4 bg-[#FFFFFF] overflow-auto">
          {tab?.map((item, index) => {
            return (
              <p
                key={index}
                className={clsx(
                  "text-[16px] md:text-[20px] font-medium cursor-pointer min-w-max flex flex-col",
                  tabValue === index ? "text-[#4949FF]" : "text-[#565656]",
                  item?.isComingSoon ? "pointer-events-none" : ""
                )}
                onClick={() => handleScrollTo(index, item?.id)}
              >
                {item?.label}
                {item?.isComingSoon && (
                  <span className="text-[#32325D] text-[12px]">
                    Coming Soon
                  </span>
                )}
              </p>
            );
          })}
        </div>

        <ArticleSection />

        <PressReleaseSection />

        <AwardsSection />

        <InterviewsSection />  

        <div className="px-4 py-10 md:p-10 my-20">
          <div className="bg-[#224C7F] lg:mx-20 lg:w-[80%] p-10 rounded-[30px] relative">
            <div className="lg:w-[70%]">
              <p className="text-[#FFFFFF] font-extrabold text-[24px] md:text-[40px]">
                Media Kit
              </p>
              <p className="text-[#FFFFFF] md:text-[20px] mt-4">
                Download a .zip folder with company profile, logo, leadership
                profiles, and office images. For media inquiries or information
                about Safexpay Payments, contact us at{" "}
                <strong>care@safexpay.com</strong>
              </p>
              <div className="flex flex-col md:flex-row items-center gap-4 md:gap-10 mt-10">
                <button className="bg-[#FFFFFF] text-[#224C7F] py-2 px-8 rounded-[10px] cursor-default flex flex-col">
                  <span>Download Media Kit</span>
                  <span className="mx-auto text-[12px]">Coming Soon</span>
                </button>
                <button
                  className="text-[#FFFFFF] border-[#FFFFFF] border-[1px] bg-[#224C7F] py-2 px-8 rounded-[10px] cursor-pointer"
                  onClick={() => {
                    navigate(routes_path?.privacyPoliciy);
                  }}
                >
                  Privacy Policy
                </button>
              </div>
              <img
                src={mediaKitImg}
                alt="dream role"
                className="lg:absolute -right-40 top-[50%] lg:-translate-y-[50%] lg:w-[40%] mt-10 lg:mt-0"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HOC(MediaAndAwards);
