import aasaan from "../../assets/images/Products-images/aasaan.png";
import payManage from "../../assets/images/Products-images/aggregation.png";
import api from "../../assets/images/Products-images/api.png";
import billPayment from "../../assets/images/Products-images/bill-payment.png";
import connectedBanking from "../../assets/images/Products-images/connect-banking.png";
import neux from "../../assets/images/Products-images/neux.png";
import onboarding from "../../assets/images/Products-images/onboarding.png";
import payouts from "../../assets/images/Products-images/payouts.png";
import whitelabel from "../../assets/images/Products-images/whitelabel.png";
import businessStandards from "../../assets/images/about-images/business-std.svg";
import combinator from "../../assets/images/about-images/combinator.svg";
import economicTimes from "../../assets/images/about-images/economic-times.svg";
import hindu from "../../assets/images/about-images/hindu.svg";
import inc from "../../assets/images/about-images/inc.svg";
import mint from "../../assets/images/about-images/mint.svg";
import productHunt from "../../assets/images/about-images/product-hunt.svg";
import techAsia from "../../assets/images/about-images/tech-asia.svg";
import techCrunch from "../../assets/images/about-images/tech-crunch.svg";
import yourStory from "../../assets/images/about-images/your-story.svg";
import aasaanClient1 from "../../assets/images/clientele/aasaan-client-1.png";
import aasaanClient2 from "../../assets/images/clientele/aasaan-client-2.png";
import aasaanClient3 from "../../assets/images/clientele/aasaan-client-3.png";
import aasaanClient4 from "../../assets/images/clientele/aasaan-client-4.png";
import aasaanClient5 from "../../assets/images/clientele/aasaan-client-5.png";
import client2 from "../../assets/images/clientele/client2.png";
import { default as client3, default as testimonialBrand3 } from "../../assets/images/clientele/client3.png";
import client4 from "../../assets/images/clientele/client4.png";
import client5 from "../../assets/images/clientele/client5.png";
import client6 from "../../assets/images/clientele/client6.png";
import client7 from "../../assets/images/clientele/client7.png";
import rating from "../../assets/images/testimonial-images/rating.png";
import testimonialBrand1 from "../../assets/images/testimonial-images/testimonial-brand-1.png";
import testimonialBrand2 from "../../assets/images/testimonial-images/testimonial-brand-2.png";
import { routes_path } from "../../config/routesPath";

export const productData = [
  {
    iconUrl: payManage,
    productName: "Payment Platform",
    productDescription:
      "Multiple payment options. Quick activation. Whitelabeling available with faster go-live",
    navigateUrl: routes_path?.paymentPlatform
  },
  {
    iconUrl: neux,
    productName: "NeuX",
    productDescription:
      "Efficiently manage your B2B payments with the best features from transfer of money to your sales purchase, catalogue management & analytics",
      navigateUrl: routes_path?.neuX
  },
  {
    iconUrl: aasaan,
    productName: "Aasaan",
    productDescription:
      "Revolutionise your shopping experience and drive higher conversion rates on the brand's website with India's 1st merchant focused checkout solutions.",
    navigateUrl: routes_path?.aasaan
  },
  {
    iconUrl: whitelabel,
    productName: "Whitelabel",
    productDescription: "Maintain your brand identity with ease and avail all benefits through our custom made solutions and platforms",
    navigateUrl: routes_path?.whitelabel
  },
  {
    iconUrl: billPayment,
    productName: "Bill Payment",
    productDescription:
      "360 degree Suite with API integration and white-labelled portal for offline channels.",
    navigateUrl: routes_path?.onox
  },
  {
    iconUrl: api,
    productName: "APIX",
    productDescription: "Begin your Verification journey & reduce costs with our new era of API platform.",
    navigateUrl: routes_path?.apix
  },
  {
    iconUrl: payouts,
    productName: "Payouts",
    productDescription: "Enable recipients to choose their preferred payment mode",
    navigateUrl: routes_path?.payouts
  },
  {
    iconUrl: onboarding,
    productName: "Digital On-boarding Solution",
    productDescription: "Experience the best CRM and advanced on-boarding platform which can meet all automation needs",
    navigateUrl: routes_path?.apix
  },
  {
    iconUrl: connectedBanking,
    productName: "Connected Banking",
    productDescription: "Manage your business finances easily by connecting accounts on one dashboard",
    navigateUrl: routes_path?.payouts
  },
];

export const testimonialData = [
  {
    id: 1,
    url: testimonialBrand1,
    message:
      "This is to show our appreciation for the marathon effort you guys put in for us. We wanted to say a very BIG THANK YOU for the effort and your continued support.We appreciate and love you all!",
    name: "Trish Kunath",
    company_profile: "Team Atlantis",
    rating: rating,
  },
  {
    id: 2,
    url: testimonialBrand3,
    message:
      "We at Railway Recruitment Cell Northern Railway hereby state that during the online application for engagement of Act Apprentices, we have availed services of Safexpay's Payment Gateway and duly appreciate the services for its exemplary features. We are highly pleased as their services has benefitted significantly due to the excellent services rendered by your team.",
    name: "Mukesh Kumar",
    company_profile: "APO",
    rating: rating,
  },
  {
    id: 3,
    url: testimonialBrand2,
    message:
      "Customer Support at Safexpay is prompt, courteous, and extremely helpful. Whenever I run into a problem, the team is always there with a prompt solution. I've found their swift customer service to be amazing and has been helpful in resolving my concerns.",
    name: "Umesh Arya",
    company_profile: "IT Head, PCJ jewellers",
    rating: rating,
  },
];

export const featuredData = [
  {
    url: businessStandards,
    alt: "business standards logo",
  },
  {
    url: hindu,
    alt: "The Hindu logo",
  },
  {
    url: mint,
    alt: "mint logo",
  },
  {
    url: yourStory,
    alt: "yourstory logo",
  },
  {
    url: productHunt,
    alt: "product hunt logo",
  },
  {
    url: combinator,
    alt: "combinator logo",
  },
  {
    url: techCrunch,
    alt: "tech crunch logo",
  },
  {
    url: techAsia,
    alt: "tech asia logo",
  },
  {
    url: inc,
    alt: "inc logo",
  },
  {
    url: economicTimes,
    alt: "economic times logo",
  },
  {
    url: businessStandards,
    alt: "business standards logo",
  },
  {
    url: hindu,
    alt: "The Hindu logo",
  },
  {
    url: mint,
    alt: "mint logo",
  },
  {
    url: yourStory,
    alt: "yourstory logo",
  },
  {
    url: productHunt,
    alt: "product hunt logo",
  },
  {
    url: combinator,
    alt: "combinator logo",
  },
  {
    url: techCrunch,
    alt: "tech crunch logo",
  },
  {
    url: techAsia,
    alt: "tech asia logo",
  },
  {
    url: inc,
    alt: "inc logo",
  },
  {
    url: economicTimes,
    alt: "economic times logo",
  },
];

export const clienteleData = [
  // {
  //   alt_text: "Clientele",
  //   imgUrl: client1
  // },
  {
    alt_text: "Clientele",
    imgUrl: client2
  },
  {
    alt_text: "Clientele",
    imgUrl: client3
  },
  {
    alt_text: "Clientele",
    imgUrl: client4
  },
  {
    alt_text: "Clientele",
    imgUrl: client5
  },
  {
    alt_text: "Clientele",
    imgUrl: client6
  },
  {
    alt_text: "Clientele",
    imgUrl: client7
  },
  // {
  //   alt_text: "Clientele",
  //   imgUrl: client8
  // },
]

export const aasaanClienteleData = [
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient1
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient2
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient3
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient4
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient5
  },
]

export const bringProficiencyData = [
  {
    title: "Powerful Dashboards and Portals",
    description: "Unlock sales growth with transactional insights &powerful features"
  },
  {
    title: "Multi-platform & plugin compatibility",
    description: "Best in class uptime of 99.9%. & plugins like Shopify, Woo-comerce etc."
  },
  {
    title: "Experience the best",
    description: "A strong and reliable digital infrastructure capable of handling 1M+ transactions daily."
  },
  {
    title: "Cloud agnostic support",
    description: "Perform consistently across platforms, even when migrating."
  },
  {
    title: "Secure",
    description: "We're PCI DSS Level 1 compliant, so you don't have to worry about meeting regulations."
  },
  {
    title: "Easy Integration",
    description: "Integrate and go live in less than an hour with Safexpay's platform plugins."
  },
]