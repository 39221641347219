import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { toastConfig } from "react-simple-toasts";
import { AppConfig } from "../../apiservice/AppConfig";
import next from "../../assets/images/testimonial-images/next.png";
import portrait from "../../assets/images/testimonial-images/portrait-happy-smiling_WteIIow 1.png";
import previous from "../../assets/images/testimonial-images/previous.png";
import Button from "../../common-component/button/Button";
import RadioButton from "../../common-component/radio-button/RadioButton";
import TextInput from "../../common-component/textinput/TextInput";
import { routes_path } from "../../config/routesPath";
import { featuredData, testimonialData } from "./homeData";
import fetchApi from "../../apiservice/ApiService";
import SelectDropdown from "../../common-component/select-dropdown/SelectDropdown";

const HomeAbout = () => {
  const navigate = useNavigate();
  const [slide, setSlide] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recentBlogsData, setRecentBlogsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSelectProduct = (data) => {
    if (product.includes(data)) {
      setProduct((item) => item.filter((ele) => ele !== data));
    } else {
      setProduct([...product, data]);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      const mail = e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setForm({ ...form, [e.target.name]: e.target.value });
      if (mail) {
        setIsValidEmail(true);
        setError({ ...error, email: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, email: "" });
      } else {
        setError({ ...error, email: "Please enter a valid email address." });
      }
    } else if (e.target.name === "phone") {
      const tel = e.target.value.match(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/gm);
      setForm({ ...form, [e.target.name]: e.target.value });
      if (tel) {
        setIsValidPhone(true);
        setError({ ...error, phone: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, phone: "" });
      } else {
        setError({ ...error, phone: "Please enter a valid phone number." });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handlePrevious = () => {
    setSlide(slide - 1);
  };

  const handleNext = () => {
    setSlide(slide + 1);
  };

  const handleSubmit = () => {
    setLoader(true);
    if (
      product?.length === 0 ||
      form?.email === "" ||
      form?.first_name === "" ||
      form?.last_name === "" ||
      form?.phone === "" ||
      form?.message === "" ||
      selectedCity === null ||
      selectedState === null
    ) {
      toastConfig({ theme: "failure" });
      toast("Please fill all the mandatory fields to send message.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }
    if (isValidEmail && isValidPhone) {
      const _form = new FormData();
      _form.append("first_name", form?.first_name);
      _form.append("last_name", form?.last_name);
      _form.append("email", form?.email);
      _form.append("phone", form?.phone);
      _form.append("message", form?.message);
      _form.append("page_name", "home_page");
      _form.append("city", selectedCity?.id);
      _form.append("state", selectedState?.id);
      product?.forEach((item, index) => {
        _form.append(`product[${index}]`, item);
      });
      axios
        .post(AppConfig?.baseUrl + "customer/landing/contact-us", _form)
        .then((response) => {
          setForm({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            message: "",
          });
          setProduct("");
          setSelectedCity(null);
          setSelectedState(null);
          document
            .querySelectorAll(".common-component")
            .forEach((component) => {
              component.checked = false;
            });
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
          setIsValidEmail(false);
          setIsValidPhone(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error?.response?.data?.errors);
          setShowOptions(true);
          toastConfig({ theme: "failure" });
          if (error?.response?.data?.message) {
            toast(error?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  const handleGetCities = (id) => {
    fetchApi
      .getCities(id)
      .then((response) => {
        let newCitiesArray = [];
        response?.data?.data?.forEach((item) => {
          newCitiesArray.push({ id: item?.id, label: item?.name });
        });
        setCitiesData(newCitiesArray);
      })
      .catch((error) => {
        console.log(error);
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  const handleGetStates = () => {
    fetchApi
      .getStates()
      .then((response) => {
        let newStateArray = [];
        response?.data?.data?.forEach((item) => {
          newStateArray.push({ id: item?.id, label: item?.name });
        });
        setStatesData(newStateArray);
      })
      .catch((error) => {
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  useEffect(() => {
    handleGetRecentBlogs();
    handleGetStates();
  }, []);

  const handleGetRecentBlogs = () => {
    setLoading(true);
    fetchApi
      .getRecentBlogs()
      .then((res) => {
        setRecentBlogsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* Also featured on section */}
      <section className="pb-10 pt-14 bg-[#0B253F]">
        <p className="text-[34px] text-white font-bold text-center">
          Also featured on
        </p>
        <div className="overflow-hidden">
          <div className="max-w-[1140px] mx-auto pt-10 flex gap-4 items-center marquee-effect">
            {featuredData?.map((item, index) => {
              return (
                <span
                  className="mb-[40px] mx-[0px] lg:mx-[10px] w-[130px] lg:w-[200px]"
                  key={index}
                >
                  <img
                    src={item?.url}
                    alt={item.alt}
                    className="max-w-[130px] lg:max-w-[200px] max-h-[75px]"
                  />
                </span>
              );
            })}
          </div>
        </div>
      </section>

      {/* Testimonial section */}
      <section className="testimonial-bg">
        <div>
          <p className="text-white text-[28px] font-bold text-center b-border">
            You're in Good Hands
          </p>
        </div>

        <div className="max-w-[1140px] mx-4 lg:mx-auto py-10 mt-8 h-auto">
          <div className="flex items-center gap-4">
            <div className="w-[10%] flex justify-start">
              {slide !== 0 && (
                <img
                  src={previous}
                  alt="previous"
                  className="cursor-pointer relative z-[60]"
                  onClick={() => handlePrevious()}
                />
              )}
            </div>
            {testimonialData?.map((item, index) => {
              return (
                <div
                  className={clsx(
                    "w-[80%] flex-col md:flex-row min-h-[320px] items-center gap-4",
                    slide === index ? "flex" : "hidden"
                  )}
                  key={index}
                >
                  <div className="md:w-[40%] flex justify-center">
                    <img
                      src={item?.url}
                      alt="testimonial profile"
                      className="w-[150px] h-[150px] md:w-[250px] md:h-[250px] object-contain rounded-[20px]"
                    />
                  </div>
                  <div className="md:w-[60%] text-[#FFFFFF] upper-quote">
                    <p className="text-[14px] md:text-[20px]">
                      {item?.message}
                    </p>
                    <p className="font-black text-[18px] md:text-[24px] mt-6">
                      {item?.name}
                    </p>
                    <p className="text-[14px] md:text-[16px] font-semibold">
                      {item?.company_profile}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="w-[10%] flex justify-end">
              {slide !== testimonialData?.length - 1 && (
                <img
                  src={next}
                  alt="next"
                  className="cursor-pointer"
                  onClick={() => handleNext()}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center gap-2 mt-10">
            {[...Array(testimonialData?.length)]?.map((_, index) => {
              return (
                <button
                  key={index}
                  className={clsx(
                    "w-[10px] h-[10px] rounded-full",
                    slide === index ? "bg-[#2B83EA]" : "bg-[#C4C4C4]"
                  )}
                  onClick={() => setSlide(index)}
                ></button>
              );
            })}
          </div>
        </div>
      </section>

      {/* Blog section */}
      <section className="py-14 px-4 justify-center">
        <div className="font-NunitoSans">
          <div className="text-center max-w-[1140px] mx-auto">
            <p className="text-[#283c91] text-[35px] lg:text-[38px] font-bold px-4 lg:px-40">
              Our Blogs
            </p>
          </div>

          {loading ? (
            <div className="grid lg:grid-cols-3 max-w-[1140px] lg:mx-auto mx-0 gap-1 lg:gap-6 relative my-4">
              {[...Array(3)]?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className="skeleton--img h-[250px!important]"
                  >
                    <span></span>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 max-w-[1140px] lg:mx-auto mx-0 gap-1 lg:gap-6 relative my-4">
              {recentBlogsData?.length > 0 &&
                recentBlogsData?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="font-NunitoSans relative overflow-hidden group rounded-[20px]"
                        key={index}
                      >
                        <img
                          src={item["image_url"]}
                          alt="Sales"
                          className="mx-auto w-auto cursor-pointer group-hover:scale-110 transition-all duration-500"
                          onClick={() => {
                            navigate(
                              `/blog/${item["title"]
                                .toLowerCase()
                                .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                                .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${
                                item?.id
                              }`,
                              {
                                state: {
                                  blog: item,
                                },
                              }
                            );
                          }}
                        />
                        <p className="text-[#ffffff] absolute bottom-0 py-4 px-2 bg-gradient-to-b from-[#00000000] to-[#000000] text-[18px] lg:text-[20px] mt-4 font-bold cursor-pointer w-full">
                          {item["title"]}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </section>

      {/* Ready to begin */}
      <section className="grid lg:flex items-start max-w-[1140px] mx-4 lg:mx-auto py-10">
        <div className="bg-[#C9F0F5] lg:w-[35%] pt-6 px-6 rounded-[10px]">
          <p className="text-[#32325D] text-[40px] font-bold">
            Ready to Begin?
          </p>
          <p className="text-[#32325D] text-[16px]">
            Easily kickstart your journey of payments and solutions via Safexpay
            by registering or contact sales to tailor your package to perfectly
            fit your business requirements.
          </p>
          <img src={portrait} alt="contact form" width={300} className="mt-4" />
        </div>
        <div className="grid gap-8 px-4 py-10 md:px-10 md:py-0 lg:w-[65%] font-poppins">
          <div className="grid lg:grid-cols-2 gap-6 lg:gap-10">
            <TextInput
              name={"first_name"}
              label={"First Name"}
              type={"text"}
              value={form?.first_name}
              required
              onChange={(e) => {
                handleChange(e);
                if (e.target.name !== "") {
                  setError({ ...error, first_name: "" });
                }
              }}
              error={Boolean(error?.first_name)}
              helperText={error?.first_name}
            ></TextInput>
            <TextInput
              name={"last_name"}
              label={"Last Name"}
              type={"text"}
              value={form?.last_name}
              required
              onChange={(e) => {
                handleChange(e);
                if (e.target.name !== "") {
                  setError({ ...error, last_name: "" });
                }
              }}
              error={Boolean(error?.last_name)}
              helperText={error?.last_name}
            ></TextInput>
            <TextInput
              name={"email"}
              label={"Email"}
              type={"email"}
              required
              value={form?.email}
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(error?.email)}
              helperText={error?.email}
            ></TextInput>
            <TextInput
              name={"phone"}
              label={"Phone Number"}
              value={form?.phone}
              type={"number"}
              required
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(error?.phone)}
              helperText={error?.phone}
            ></TextInput>
            <SelectDropdown
              label={"State"}
              placeHolder="Select State"
              data={statesData}
              value={selectedState?.label}
              onClick={(val) => {
                setSelectedState(val);
                handleGetCities(val?.id);
              }}
              required
              error={Boolean(error?.state)}
              helperText={error?.state}
            />
            <SelectDropdown
              label={"City"}
              placeHolder="Select City"
              message="Please choose a state in order to select a city."
              data={citiesData}
              value={selectedCity?.label}
              onClick={(val) => {
                setSelectedCity(val);
              }}
              required
              error={Boolean(error?.city)}
              helperText={error?.city}
            />
          </div>
          <div
            className={clsx(
              "border-[1px] rounded-[14px] p-6 md:max-w-[80%]",
              Boolean(error?.product) ? "border-red-500" : "border-[#A5A5A5]"
            )}
          >
            <p
              className={clsx(
                "font-semibold cursor-pointer w-max",
                Boolean(error?.product) ? "text-red-500" : "text-[#212121]"
              )}
              onClick={() => setShowOptions(!showOptions)}
            >
              Select Product* <span className="triangle-down"></span>
            </p>
            {showOptions && (
              <div
                className={clsx(
                  "grid lg:grid-cols-3 gap-x-10 gap-y-3 p-4 transition-all duration-500"
                )}
                name="product"
              >
                <RadioButton
                  id="radio-button-1"
                  label={"Payment Platform"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("Payment Platform");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-2"
                  label={"Payouts"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("Payouts");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-3"
                  label={"Neux"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("Neux");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-4"
                  label={"Aasaan"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("Aasaan");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-5"
                  label={"APIX"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("APIX");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-6"
                  label={"ONOX"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("ONOX");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-7"
                  label={"White label"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("White label");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
                <RadioButton
                  id="radio-button-8"
                  label={"Others"}
                  name={"product"}
                  value={product}
                  onClick={() => {
                    handleSelectProduct("Others");
                    setError({ ...error, product: "" });
                  }}
                ></RadioButton>
              </div>
            )}
          </div>
          <div className="grid text-[#212121] text-[14px]">
            <label
              className={clsx(
                "font-medium",
                Boolean(error?.message) ? "text-red-500" : ""
              )}
            >
              Message *
            </label>
            <textarea
              name="message"
              placeholder="Write your message.."
              className={clsx(
                "outline-none py-2 border-b-[1px] resize-none",
                Boolean(error?.message)
                  ? "border-b-red-500"
                  : "border-b-[#A5A5A5]"
              )}
              value={form?.message}
              onChange={(e) => {
                handleChange(e);
                if (e.target.name !== "") {
                  setError({ ...error, message: "" });
                }
              }}
            ></textarea>
            {Boolean(error?.message) && (
              <p className="text-[12px] text-red-500 mt-1">{error?.message}</p>
            )}
          </div>
          <div className="flex justify-end">
            {loader ? (
              <p
                className="bg-[#32325D] text-white py-2 px-4 flex justify-center items-center rounded-md w-[150px]"
                onClick={() => {
                  toastConfig({ theme: "failure" });
                  toast("please wait! we are processing your request.");
                }}
              >
                <span className="loader"></span>
              </p>
            ) : (
              <Button
                text={"Send Message"}
                backgroundColor={"#32325D"}
                onClick={() => {
                  handleSubmit();
                }}
              ></Button>
            )}
          </div>
        </div>
      </section>

      {/* choose safexpay */}
      <section className="choose-safex-bg">
        <div className="text-white text-center grid gap-10 justify-center max-w-[600px] mx-auto">
          <p className="text-[28px] lg:text-[40px] font-bold">
            Choose Safexpay
          </p>
          <p className="text-[14px] lg:text-[18px]">
            Provide innovative solutions and experience to your customers. Be
            part of the Best Fintech Ecosystem
          </p>
          <button
            className="bg-[#01A30F] py-3 px-12 w-max mx-auto rounded-[80px]"
            onClick={() => {
              navigate(routes_path?.contactUs);
            }}
          >
            I'm Interested
          </button>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
