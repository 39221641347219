import clsx from "clsx";
import parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import check from "../assets/social-icons/check.png";
import chevronRight from "../assets/social-icons/faq-chevron-right.png";
import HOC from "../component/HOC";
import {
  PrivacyPolicyDataNew,
  privacyPolicyChildData,
} from "./PrivacyPolicyData";
import fetchApi from "../apiservice/ApiService";

const PrivacyPolicy = () => {
  const [openDetails, setOpenDetails] = useState("");
  const [faqDetails, setFaqDetails] = useState("");
  const [showMainContainer, setShowMainContainer] = useState("");
  const [openChild, setOpenChild] = useState("");
  const [privacy_Policy, setPrivacy_Policy] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Privacy Policy | Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetPrivacyPolicy();

    const paragraphs = document.querySelectorAll("p");

    paragraphs.forEach((parentParagraph) => {
      const childParagraph = parentParagraph.querySelector("img");

      if (childParagraph) {
        parentParagraph.classList.add("flex-summernote");
      }
    });
  }, []);

  const handleGetPrivacyPolicy = () => {
    setLoading(true);
    fetchApi
      .getPrivacyPolicy()
      .then((response) => {
        setPrivacy_Policy(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenDetails = (key, index) => {
    setOpenDetails(openDetails === key ? "" : key);
    setFaqDetails(index);
  };

  return (
    <>
      <div className="terms-bg flex justify-center items-center">
        <p className="text-white text-[32px] lg:text-[47px] font-bold text-center">
          Company Policy
        </p>
      </div>

      {loading ? (
        <div className="flex flex-col items-center justify-center py-20">
          <div className="page-loader"></div>
        </div>
      ) : (
        <div className="mt-10 flex justify-center my-10 mx-4 text-[#57647C]">
          <div className="w-full">
            <div className={clsx("lg:mx-20 my-2")}>
              <div className="flex justify-between items-center border-b-2 broder-b-[#B5B5B5]  mb-2 lg:mb-10 h-[50px]">
                <p className="lg:text-[32px] text-sm font-semibold text-[#1A59B2]">
                  {privacy_Policy?.[0]?.title}
                </p>
              </div>
              <div>
                <p className="whitespace-pre-line d-flex">
                  {privacy_Policy?.[0]?.description &&
                    parser(privacy_Policy?.[0]?.description)}
                </p>
                {/* <p className="whitespace-pre-line">
                Paygate India Private Limited(‘Paygate’) and all its
                subsidiaries (includes all subsidiaries across globe) are
                committed for protecting and respecting your information that
                will be provided by you by filling the forms on our site. The
                Privacy Policy has been prepared to ensure that your privacy is
                protected and secured. You can be assured that we shall use your
                personal information only in accordance with this Privacy
                Policy. Please read and understand carefully that the
                PaygatePrivacy Policy is to know our views and practices
                regarding your personal data and on how we use it.
              </p>
              <br />
              <p className="text-[#212121] font-semibold">
                The Information which you have provided at the time of
                registration on our website and while subscription of our
                various services this may include but not limited to:
              </p>
              <ul className="py-2 text-[#577684]">
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Name
                </li>
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Designation
                </li>
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Contact information including email address.
                </li>
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Business information.
                </li>
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Demographic information like postcodes, preferences and
                  interests.
                </li>
                <li className="flex items-center py-1 gap-3">
                  <img src={check} alt="check" width={20} />
                  Any other information related / relevant to our application
                  process.
                </li>
              </ul> */}
              </div>
            </div>
            {privacy_Policy?.[0]?.privacy_policy?.length > 0 &&
              privacy_Policy?.[0]?.privacy_policy?.map((ele, key) => {
                return (
                  <div
                    className={clsx(
                      "py-6 lg:mx-20 mx-4",
                      key !== privacy_Policy?.[0]?.privacy_policy?.length - 1
                        ? "border-b-2"
                        : ""
                    )}
                    key={key}
                  >
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => {
                        setOpenChild(openChild === key ? "" : key);
                      }}
                    >
                      <p className="text-[#3D525C] font-bold text-[20px] ">
                        {ele?.title}
                      </p>
                      <img
                        src={chevronRight}
                        alt="right arrow"
                        className={clsx(
                          "transition-all duration-500",
                          openChild === key ? "rotate-90" : "rotate-0"
                        )}
                      />
                    </div>
                    <div
                      className={clsx(
                        "font-light transition-all duration-500 overflow-hidden",
                        openChild === key ? "h-auto visible" : "h-0 invisible"
                      )}
                    >
                      <div className="my-2 whitespace-pre-line">
                        {ele?.description && parser(ele?.description)}
                      </div>
                      {/* <ul className="py-2 text-[#577684]">
                      {ele?.listData?.length > 0 &&
                        ele?.listData?.map((li, i) => {
                          return (
                            <>
                              <li
                                className="flex items-center py-1 gap-3"
                                key={i}
                              >
                                <img src={check} alt="check" width={20} />
                                {li}
                              </li>
                            </>
                          );
                        })}
                    </ul> */}
                    </div>
                  </div>
                );
              })}
            {privacy_Policy
              ?.slice(1, privacy_Policy?.length)
              ?.map((item, index) => {
                return (
                  <div
                    id={item?.id}
                    className={clsx("lg:mx-20 my-2")}
                    key={index}
                  >
                    <div
                      className="flex justify-between items-center border-b-2 broder-b-[#B5B5B5]  mb-2 lg:mb-10 cursor-pointer h-[50px]"
                      onClick={() => {
                        setShowMainContainer(
                          showMainContainer === index ? "" : index
                        );
                        if (showMainContainer !== index) {
                          document.getElementById(item?.id).scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }}
                    >
                      <p className="lg:text-[32px] text-sm font-semibold text-[#1A59B2]">
                        {item?.title}
                      </p>

                      <img
                        src={chevronRight}
                        alt="right arrow"
                        className={clsx(
                          "transition-all duration-500 w-[30px] md:w-[50px]",
                          showMainContainer === index ? "hidden" : "block"
                        )}
                      />
                    </div>
                    <div
                      className={clsx(
                        showMainContainer === index ? "block" : "hidden"
                      )}
                    >
                      <div className="whitespace-pre-line d-flex">
                        {item?.description && parser(item?.description)}
                      </div>
                      {/* {item?.isParentCheckList && (
                    <ul className="py-2 text-[#577684]">
                      {item?.parentListData?.length > 0 &&
                        item?.parentListData?.map((li, i) => {
                          return (
                            <>
                              <li
                                className="flex items-center py-1 gap-3"
                                key={i}
                              >
                                <img src={check} alt="check" width={20} />
                                {li}
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  )} */}
                      {item?.privacy_policy?.length > 0 &&
                        item?.privacy_policy?.map((ele, key) => {
                          return (
                            <div
                              className={clsx(
                                "py-6 mx-4",
                                key !== item?.privacy_policy?.length - 1
                                  ? "border-b-2"
                                  : ""
                              )}
                              key={key}
                            >
                              <div
                                className="flex justify-between items-center cursor-pointer"
                                onClick={() => {
                                  handleOpenDetails(key, index);
                                }}
                              >
                                <p className="text-[#3D525C] font-bold text-[20px] ">
                                  {ele?.title}
                                </p>
                                <img
                                  src={chevronRight}
                                  alt="right arrow"
                                  className={clsx(
                                    "transition-all duration-500",
                                    openDetails === key && faqDetails === index
                                      ? "rotate-90"
                                      : "rotate-0"
                                  )}
                                />
                              </div>
                              <div
                                className={clsx(
                                  "font-light transition-all duration-500 overflow-hidden",
                                  openDetails === key && faqDetails === index
                                    ? "h-auto visible"
                                    : "h-0 invisible"
                                )}
                              >
                                <div className="my-2 whitespace-pre-line">
                                  {parser(ele?.description)}
                                </div>
                                {/* <ul className="py-2 text-[#577684]">
                              {ele?.listData?.length > 0 &&
                                ele?.listData?.map((li, i) => {
                                  return (
                                    <>
                                      <li
                                        className="flex items-center py-1 gap-3"
                                        key={i}
                                      >
                                        <img src={check} alt="check" width={20} />
                                        {li}
                                      </li>
                                    </>
                                  );
                                })}
                            </ul> */}
                              </div>
                            </div>
                          );
                        })}
                      {/* <p className="whitespace-pre-line">
                    {parser(item?.paragraph1)}
                  </p> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default HOC(PrivacyPolicy);
