import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../assets/css/product.css'

const HOC = (Comp) => {
  const newComp = () => {
    return (
      <>
        <Header />
          <Comp />
        <Footer />
      </>
    )
  }
  return newComp;
}

export default HOC