import React, { useEffect, useState } from "react";
import check from "../assets/social-icons/check.png";
import HOC from "../component/HOC";
import fetchApi from "../apiservice/ApiService";
import parser from "html-react-parser";

const TermsCondition = () => {
  const [termsAndCondition, setTermsAndCondition] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Terms & Conditions | Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetTC();
  }, []);

  const handleGetTC = () => {
    setLoading(true);
    fetchApi
      .getTermsAndCondition()
      .then((response) => {
        setTermsAndCondition(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="terms-bg flex justify-center items-center">
          <p className="text-white text-[32px] lg:text-[47px] font-bold text-center">
            TERMS & CONDITIONS
          </p>
        </div>
        {loading ? (
          <div className="flex flex-col items-center justify-center py-20">
            <div className="page-loader"></div>
          </div>
        ) : (
          <div className="my-10 mx-4 lg:mx-20 d-flex">
            {termsAndCondition?.description &&
              parser(termsAndCondition?.description)}
          </div>
        )}
        {/* <div className="my-10 mx-4 lg:mx-20">
          <ul className="text-[#577684] leading-[1.7]">
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              This website pages content is for your general information and
              uses only. It is subject to change without prior notice.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              Neither we nor any third parties provide any warranty or guarantee
              the accuracy, timeliness, performance, completeness or suitability
              of the information and materials found or offered on this website
              for any particular purpose. By visiting the website you
              acknowledge that such information and materials may contain
              inaccuracies or errors and we expressly exclude liability for any
              such inaccuracies or errors to the fullest extent permitted by
              law.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              This website contains material which is owned by or licensed to
              us. This material includes, but is not limited to, the design,
              layout, look, appearance and graphics. Reproduction is prohibited
              other than in accordance with the copyright notice, which forms
              part of these terms and conditions.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              All trademarks reproduced in this website, which is not the
              property of, or licensed to the operator, are acknowledged on the
              website.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              Unauthorized use of this website shall be subjected to a claim for
              damages and/or be a criminal offence.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              From time to time, this website may also include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s). We have no responsibility for the content of the
              linked website(s).
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              You may not create a link to this website from another website or
              document without Safexpay's prior written consent.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              If you choose, or you are provided with, a user identification
              code, password or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential. You must not disclose it to any third party.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              We have the right to disable any user identification code or
              password, whether chosen by you or allocated by us, at any time,
              if in our reasonable opinion you have failed to comply with any of
              the provisions of these terms of use.
            </li>
            <li className="flex items-center py-4 gap-3">
              <img src={check} alt="check" width={20} />
              Your use of this website and any dispute arising out of such use
              of the website is subject to the exclusive jurisdiction of the
              courts of Haryana State Government of India.
            </li>
          </ul>
        </div>
        <div className="mb-20 mx-4 lg:mx-20 leading-[1.7]">
          <p className="text-[#283C91] text-[32px] pb-2">
            Acceptance of Terms through Use
          </p>
          <p className="text-[#577684]">
            The data we collect from you are stored in our system servers and
            will be treated with utmost confidentiality. Your personal
            information will be used only by Safexpay for all business
            transactions that require your information to complete the
            transactions.
          </p>
          <p className="text-[#577684]">
            Safexpay may allow a third-party application to access your
            information in cases where a need arises for verification of your
            data and other validation purposes. Safexpay ensures that
            verification processes used by third party application will help to
            make your account safe from fraud or any money laundering.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default HOC(TermsCondition);
