import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import noCode from "../../assets/images/Products-images/whitelabel-nocode.png";
import wlPAggregator from "../../assets/images/Products-images/whitelabel-pay-aggregator.png";
import wlPNeobank from "../../assets/images/Products-images/whitelabel-pay-neobanking.png";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import {
  WLPAData,
  WLPNData,
  whiteLabelSolutionData,
  whitelabelData,
} from "./productDetailsData";

const Whitelabel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "White Label - Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="whitelabel-bg flex flex-col md:justify-center items-start px-4 py-10 lg:py-0 lg:px-20 font-roboto">
          <div className="flex justify-end">
            <div className="md:w-[68%] md:pl-24">
              <p className="text-[24px] lg:text-[36px] font-extrabold text-[#32325D]">
                Upgrade Your Brand's Payment Processing with White Label
                Aggregator
              </p>
              <p className="text-[14px] lg:text-[18px] py-4">
                Easily manage payments and maintain your brand identity with our
                robust white label payment platform
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%]">
                <button
                  className="text-white bg-[#2faa4a] py-2 px-6 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => window.open(routes_path?.signup)}
                >
                  Create Account
                </button>
                <button
                  className="text-[#2faa4a] border-[1px] bg-white text-[12px] lg:text-[18px] border-[#2faa4a] py-2 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 lg:py-20 wl-bg">
          <p className="text-[24px] lg:text-[38px] font-bold text-center text-[#32325D] px-4">
            Your Business & Safexpay:{" "}
            <span className="text-[#2575FC]">
              Uniting for Unstoppable Success
            </span>
          </p>
          <div className="text-[16px] lg:text-[18px] p-4 lg:p-10 lg:mx-20 mt-10 grid md:grid-cols-3 lg:grid-cols-4 gap-10">
            {whitelabelData?.map((item, index) => {
              return (
                <div
                  className="border-[1px] border-[#B4DBF2] rounded-[30px] text-center w-[200px] mx-auto p-8 bg-[#FFFFFF]"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto"
                  />
                  <p className="mt-4 text-[17px]">{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10">
          <p className="text-[#32325D] text-[24px] lg:text-[40px] font-bold text-center lg:px-10 lg:max-w-[75%] mx-auto">
            Customize payment experience with branded White Labeling.
          </p>

          <div className="py-10 px-4">
            <div className="flex items-start flex-col-reverse md:flex-row">
              <div className="md:w-[40%] lg:px-10 my-10">
                <p className="text-[#32325D] text-[24px] lg:text-[40px] font-bold">
                  Payment Aggregator
                </p>
                <ul className="px-6 mt-4">
                  {WLPAData?.map((item, index) => {
                    return (
                      <li
                        className="list-disc text-[18px] lg:text-[20px]"
                        key={index}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="md:w-[60%]">
                <img src={wlPAggregator} alt="whitelabel paymeny aggregator" />
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center bg-[#EBF5FE] rounded-[20px] lg:rounded-[50px] mt-10 lg:px-20 py-10">
              <div className="md:w-[60%] px-4">
                <img src={wlPNeobank} alt="whitelabel paymeny aggregator" />
              </div>
              <div className="md:w-[40%] my-10">
                <p className="text-[#32325D] text-[24px] lg:text-[40px] font-bold">
                  Payout & Neobanking
                </p>
                <ul className="px-6 mt-4">
                  {WLPNData?.map((item, index) => {
                    return (
                      <li
                        className="list-disc text-[18px] lg:text-[20px]"
                        key={index}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 wl-solution-bg isolate">
          <p className="text-[20px] lg:text-[30px] font-bold text-center text-[#32325D] px-10">
            Our Whitelabel solution gives businesses access to an unrivaled
            global network of payment solutions, allowing them to process safer
            and decentralized transactions.
          </p>

          <div className="flex pt-10 lg:pt-20">
            <div className="grid lg:w-[60%] px-10 lg:px-20">
              {whiteLabelSolutionData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col lg:flex-row items-center gap-4 lg:gap-10 py-10 relativ"
                  >
                    <div className="border-2 border-[#2575FC] p-4 rounded-full w-max h-[96px] bg-white">
                      <p
                        className={clsx(index !== 0 ? "relative wl-steps" : "")}
                      >
                        <img
                          src={item.iconUrl}
                          alt={item.title}
                          className="w-[60px] h-[60px]"
                        />
                      </p>
                    </div>
                    <div className="text-center lg:text-left w-[80%]">
                      <p className="text-[#283C91] text-[23px] font-bold">
                        {item.title}
                      </p>
                      <p className="text-[17px] whitespace-pre-line">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-10 lg:p-20 flex flex-col md:flex-row items-center gap-10 relative">
          <div className="lg:w-[50%]">
            <p className="grid text-[38px] lg:text-[60px] font-black text-[#32325D] leading-[38px] lg:leading-[60px]">
              <span>No Coding.</span>
              <span className="text-[#2575FC]">No Website.</span>
              <span>No Problem</span>
            </p>
            <p className="text-[18px] mt-8">
              Collect payments easily over social media with Payment Links
              without a single line of coding. Send links instantly for missed
              transactions or abandoned carts.
            </p>
          </div>
          <div className="w-full lg:w-[50%]">
            <img src={noCode} alt="No Code section" className="" />
          </div>
          <div className="absolute -bottom-4 left-[50%] translate-x-[-50%] w-full md:w-auto grid grid-cols-2 gap-4 mt-8 mx-auto px-6">
            <button
              className="text-white bg-[#01A30F] py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
              onClick={() => window.open(routes_path?.signup)}
            >
              Sign Up Now
            </button>
            <button
              className="text-[#01A30F] bg-white border-[1px] text-[16px] lg:text-[18px] border-[#01A30F] py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
              onClick={() => navigate(routes_path?.contactUs)}
            >
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(Whitelabel);
