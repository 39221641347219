import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routes_path } from "../config/routesPath";

const SimplifyComponent = ({ title, content, image, background, height }) => {
  const navigate = useNavigate();

  return (
    <div className="p-10 lg:py-32 lg:px-20 flex flex-col md:flex-row items-center gap-10 relative">
      <div className="lg:w-[50%]">
        <p className="grid text-[38px] lg:text-[60px] font-black text-[#32325D] leading-[38px] lg:leading-[60px]">
          SIMPLIFY <span className="text-[#2575FC]">{title}</span> WITH US!
        </p>
        <p className="text-[18px] mt-8 lg:max-w-[70%]">{content}</p>
      </div>
      <div className={clsx(`w-full lg:w-[50%] flex ${background}`)}>
        <img
          src={image}
          alt="No Code section"
          className={clsx("w-full object-contain", height ? height : "h-auto")}
        />
      </div>
      <div className="absolute -bottom-4 left-[50%] translate-x-[-50%] w-full md:w-auto grid grid-cols-2 gap-4 mt-8 mx-auto px-6">
        <button
          className="text-white bg-[#01A30F] py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
          onClick={() => window.open(routes_path?.signup)}
        >
          Sign Up Now
        </button>
        <button
          className="text-[#01A30F] bg-white border-[1px] text-[16px] lg:text-[18px] border-[#01A30F] py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
          onClick={() => navigate(routes_path?.contactUs)}
        >
          Contact Sales
        </button>
      </div>
    </div>
  );
};

export default SimplifyComponent;
