import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboard from "../../assets/images/Industries-images/Retail-Industry.png";
import simplifyImg from "../../assets/images/Products-images/simplify4.png";
import HOC from "../../component/HOC";
import SimplifyComponent from "../../component/SimplifyComponent";
import { routes_path } from "../../config/routesPath";
import { retailData } from "./industryData";

const Retail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Retail - Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="bg-[#EFF7FF] lg:h-[400px] retail-bg">
          <div className="grid md:flex items-end md:items-center justify-end gap-2 lg:gap-10 max-w-[1140px] mx-auto h-full">
            <div className="md:w-[50%] m-8">
              <p className="text-[#32325D] text-[24px] lg:text-[38px] font-extrabold lg:leading-[45px]">
                Your Trusted Partner for Faster, Smoother Transactions.
              </p>
              <p className="py-3 text-[#32325D] text-[14px] lg:text-[18px]">
                Unlock the full potential of your retail business with our
                comprehensive fintech solution
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%] mt-8">
                <button
                  className="text-white bg-[#01A30F] py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => window.open(routes_path?.signup)}
                >
                  Sign Up Today
                </button>
                <button
                  className="text-[#01A30F] border-[1px] text-[16px] lg:text-[18px] border-[#01A30F] py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-10 items-center py-10 px-8 md:px-20">
          <div className="md:w-[50%]">
            <p className="text-[#32325D] text-[20px] md:text-[24px] lg:text-[35px] font-bold text-center md:text-left">
              One stop solution to manage your catalogues & payouts
            </p>
          </div>
          <div className="pb-10 md:pb-20 h-[200px] md:h-auto flex justify-center md:w-[50%]">
            <div className="relative image-effect flex justify-center py-10">
              <img src={dashboard} alt="dashboard" />
            </div>
          </div>
        </div>

        <div className="py-10 industries-bg">
          <p className="text-[#32325D] text-[24px] lg:text-[40px] px-4 font-bold leading-[45px] text-center">
            Secure Every Sale with Confidence
          </p>

          <div className="max-w-[1140px] mx-10 lg:mx-auto flex flex-wrap justify-center gap-x-10 gap-y-10 lg:gap-y-20 my-10 lg:my-20">
            {retailData?.map((item, index) => {
              return (
                <div
                  className="bg-white rounded-[20px] text-center w-[250px] p-6 relative"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto w-[60px] lg:w-[80px]"
                  />
                  <p className="text-[#283C91] mt-1 text-[22px] font-bold">
                    {item.title}
                  </p>
                  <p className="text-[#212529] mt-3 font-medium">
                    {item.description}
                  </p>
                  <button
                    className="absolute -bottom-4 left-[50%] translate-x-[-50%] bg-[#2575FC] rounded-[5px] px-4 py-1 text-white font-bold"
                    onClick={() => navigate(item.navigationUrl)}
                  >
                    Know More
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <SimplifyComponent
          title={"PAYMENTS"}
          content={
            "Manage payments, vendor payouts, and streamline collections with ease. Contact us for custom solutions or create your account now"
          }
          image={simplifyImg}
          height={"h-[300px] md:h-[500px]"}
        />
      </div>
    </>
  );
};

export default HOC(Retail);
