import NotFound from "../error/NotFound";
import Contact from "../module/Contact-us/Contact";
import About from "../module/about/About";
import Blog from "../module/blog/Blog";
import BlogDetails from "../module/blog/BlogDetails";
import BlogViewAll from "../module/blog/BlogViewAll";
import Career from "../module/career/Career";
import Home from "../module/home/Home";
import Ecommerce from "../module/industries/Ecommerce";
import Education from "../module/industries/Education";
import Industries from "../module/industries/Industries";
import Retail from "../module/industries/Retail";
import Society from "../module/industries/Society";
import MediaAndAwards from "../module/media-and-awards/MediaAndAwards";
import PartnerProgram from "../module/partner/PartnerProgram";
import Pricing from "../module/pricing/Pricing";
import APIX from "../module/products/APIX";
import Aasaan from "../module/products/Aasaan";
import NeuX from "../module/products/NeuX";
import Onox from "../module/products/Onox";
import PaymentAggregator from "../module/products/PaymentAggregator";
import Payouts from "../module/products/Payouts";
import Whitelabel from "../module/products/Whitelabel";
import FAQ from "../terms/FAQ";
import PrivacyPolicy from "../terms/PrivacyPolicy";
import TermsCondition from "../terms/TermsCondition";
import { routes_path } from "./routesPath";

const routesConfig = [
  {
    path: routes_path?.home,
    element: <Home />,
  },
  {
    path: routes_path?.aboutUs,
    element: <About />,
  },
  {
    path: routes_path?.contactUs,
    element: <Contact />,
  },
  {
    path: routes_path?.pricing,
    element: <Pricing />,
  },
  {
    path: routes_path?.blog,
    element: <Blog />,
  },
  {
    path: routes_path?.blogById,
    element: <BlogDetails />,
  },
  {
    path: routes_path?.viewAllBlogs,
    element: <BlogViewAll />
  },
  {
    path: routes_path?.paymentPlatform,
    element: <PaymentAggregator />,
  },
  {
    path: routes_path?.payouts,
    element: <Payouts />,
  },
  {
    path: routes_path?.aasaan,
    element: <Aasaan />,
  },
  {
    path: routes_path?.whitelabel,
    element: <Whitelabel />,
  },
  {
    path: routes_path?.neuX,
    element: <NeuX />,
  },
  {
    path: routes_path?.apix,
    element: <APIX />,
  },
  {
    path: routes_path?.onox,
    element: <Onox />,
  },
  {
    path: routes_path?.partnerProgram,
    element: <PartnerProgram />,
  },
  {
    path: routes_path?.industries,
    element: <Industries />,
  },
  // {
  //   path: routes_path?.government,
  //   element: <Government />,
  // },
  {
    path: routes_path?.eCommerce,
    element: <Ecommerce />,
  },
  {
    path: routes_path?.education,
    element: <Education />,
  },
  // {
  //   path: routes_path?.hospital,
  //   element: <Hospital />,
  // },
  // {
  //   path: routes_path?.travel,
  //   element: <Travel />,
  // },
  {
    path: routes_path?.society,
    element: <Society />,
  },
  {
    path: routes_path?.retail,
    element: <Retail />,
  },
  {
    path: routes_path?.termsAndCondition,
    element: <TermsCondition />,
  },
  {
    path: routes_path?.FAQ,
    element: <FAQ />,
  },
  {
    path: routes_path?.privacyPoliciy,
    element: <PrivacyPolicy />,
  },
  {
    path: routes_path?.career,
    element: <Career />,
  },
  {
    path: routes_path?.mediaAndAwards,
    element: <MediaAndAwards />
  },
  {
    path: routes_path?.error,
    element: <NotFound />,
  },
];

export default routesConfig;
