import axios from "axios";
import { AppConfig } from "./AppConfig";

const http = axios.create({
  baseURL: AppConfig.baseUrl,
  headers: {
    Accept: "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http