import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import simplifyImg from "../../assets/images/Products-images/simplify2.png";
import educationFlow from "../../assets/video/Education Flow.mp4";
import HOC from "../../component/HOC";
import SimplifyComponent from "../../component/SimplifyComponent";
import { routes_path } from "../../config/routesPath";
import { educationData } from "./industryData";

const Education = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Education - Safexpay";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="bg-[#F3F9FE] lg:h-[450px] education-bg">
          <div className="grid md:flex items-end md:items-center justify-end gap-2 lg:gap-10 max-w-[1140px] mx-auto h-full">
            <div className="md:w-[50%] m-8">
              <p className="text-[#32325D] text-[24px] lg:text-[36px] font-extrabold lg:leading-[45px]">
                Empowering Affordable and Accessible Education
              </p>
              <p className="py-3 text-[#000000] text-[14px] lg:text-[18px]">
                Efficiently manage fees, simplify expenses, and conduct
                hassle-free online transactions for educational institutions.
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%] mt-8">
                <button
                  className="text-white bg-[#01A30F] py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => window.open(routes_path?.signup)}
                >
                  Sign Up Today
                </button>
                <button
                  className="text-[#01A30F] border-[1px] text-[16px] lg:text-[18px] border-[#01A30F] py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-10 items-center py-10 px-8 md:px-20">
          <div className="md:w-[40%]">
            <p className="text-[#32325D] text-[20px] md:text-[24px] lg:text-[35px] font-bold text-center md:text-left">
              Observe the functionality of our Educational Payment System
            </p>
          </div>
          <div className="pb-10 md:pb-20 h-[200px] md:h-auto flex justify-center md:w-[60%]">
            <div className="relative video-effect">
              <video
                autoPlay
                loop
                className="h-[150px] md:h-auto rounded-[20px]"
              >
                <source src={educationFlow} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="py-10 industries-bg">
          <p className="text-[#32325D] text-[24px] lg:text-[40px] px-4 font-bold leading-[45px] text-center">
            Comprehensive Payment Solution for
            <span className="text-[#2575FC]"> Indian Education</span>
          </p>

          <div className="max-w-[1140px] mx-10 lg:mx-auto flex flex-wrap justify-center gap-x-20 gap-y-10 lg:gap-y-20 my-10 lg:my-20">
            {educationData?.map((item, index) => {
              return (
                <div
                  className="bg-white rounded-[20px] text-center w-[300px] p-10 relative"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto w-[60px] lg:w-[80px]"
                  />
                  <p className="text-[#283C91] mt-4 text-[22px] font-bold">
                    {item.title}
                  </p>
                  <p className="text-[#212529] mt-3 font-medium">
                    {item.description}
                  </p>
                  <button
                    className="absolute -bottom-4 left-[50%] translate-x-[-50%] bg-[#2575FC] rounded-[5px] px-4 py-1 text-white font-bold"
                    onClick={() => navigate(item.navigationUrl)}
                  >
                    Know More
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <SimplifyComponent
          title={"EDUCATION PAYMENTS"}
          content={
            "Manage student fee payments, vendor payouts, and streamline collections with ease. Contact us for custom solutions or create your account now"
          }
          image={simplifyImg}
          height={"h-[300px] md:h-[500px]"}
        />
      </div>
    </>
  );
};

export default HOC(Education);
