import React, { useEffect } from 'react';
import pageNotFound from '../assets/images/page-not-found.svg';
import { useNavigate } from 'react-router-dom';
import HOC from '../component/HOC';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    document.title = "Page not found - Safexpay";
  }, []);

  return (
    <>
      <div className='h-screen flex flex-col justify-center items-center'>
        <img src={pageNotFound} className='w-[500px]' />
        <p className='text-[20px] lg:text-[40px] text-[#212529] mt-10 font-bold'>Page Not Found</p>
        <p className='text-[14px] px-2 lg:text-[16px] text-[#57647C] lg:w-[40%] text-center font-light my-6'>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        {/* <button className='bg-gradient-to-r from-[#283c91] to-[#119bd7] py-3 px-6 text-white font-medium text-[16px] rounded font-roboto' onClick={()=>{
          navigate('/');
        }}>GO TO HOME</button> */}
      </div>
    </>
  )
}

export default HOC(NotFound)